import { StarIcon } from '~/assets/icons';
import { cn } from '~/lib/utils';

export function Stars({
  rating,
  className,
  size
}: {
  rating: number;
  className?: string;
  size?: number;
}) {
  const ratingNormalized = normalizedRating(rating);

  let starComponents = [];
  for (let i = 1; i <= 5; i++) {
    const isHalf = i - 0.5 === ratingNormalized

    starComponents.push(
      <StarIcon
        key={i}
        strokeWidth={3}
        isHalf={isHalf}
        size={size || 24}
        fill={i < ratingNormalized + 1 ? undefined : '#fff'} />
    );
  }
  return (
    <div className={cn('flex flex-row justify-center mb-2 gap-2', className)}>
      {starComponents}
    </div>
  );
}

const normalizedRating = (rating: number) => {
  const ratingString = rating.toString();
  const ratingStringSplit = ratingString.split('.');
  if (ratingStringSplit.length === 1) {
    return rating;
  }
  const decimal = parseInt(ratingStringSplit[1].charAt(0));
  if (decimal >= 5) {
    return parseInt(ratingStringSplit[0]) + 0.5;
  }
  return parseInt(ratingStringSplit[0]);
}